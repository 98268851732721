<template>
  <div class="content-wrap">
	<!--研究型病房入住信息补充-->
		<Spin fix v-if="loading"></Spin>
		<div class="form-container">
			<Form
				:model="formData"
				ref="supplementaryInfoForm"
				:rules="rules"
				label-position="top"
				class="supplementary-info-form"
			>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="项目名称" prop="projectName">
							<Input v-model="formData.projectName" placeholder="请输入项目名称" disabled />
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="项目等级" prop="projectLevel">
							<Input v-model="formData.projectLevel" placeholder="请输入项目等级" />
						</FormItem>
						<p style="color:red; margin-top: -15px;">IST项目:项目级别请选择高水平GCP项目的具体类别-牵头国际多中心项目，牵头国内多中心项目、参与国际多中心项目、|类新药|期、需要审批的Ⅲ类器械、其他IST项目</p>
						<p style="color:red;">IIT项目:若为纵向课题，请选择纵向课题的级别-国家级、省部级、市局级、校级及学会级、院级;若为与企业合作，请选择医企协同横向项目;其他ITT项目</p>
					</Col>
				</Row>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="访视周期" prop="visitCycle">
							<Input v-model="formData.visitCycle" placeholder="请输入访视周期" />
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="项目进度" prop="projectSchedule">
							<Select
								v-model="formData.projectSchedule"
								placeholder="请选择项目进度"
							>
								<Option value="启动/筛选">启动/筛选</Option>
								<Option value="入组中">入组中</Option>
								<Option value="入组完成/随访">入组完成/随访</Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="责任医生" prop="primarySub">
							<Input v-model="formData.primarySub" placeholder="请输入责任医生" />
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="责任护士" prop="primaryNurse">
							<Input v-model="formData.primaryNurse" placeholder="请输入责任护士" />
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="CRU对接人" prop="cruContactPerson">
							<Input v-model="formData.cruContactPerson" placeholder="IIT项目填写" />
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="质控对接人" prop="qcContactPerson">
							<Input v-model="formData.qcContactPerson" placeholder="IST项目填写" />
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="生物样本累计采集例数" prop="stAmount">
							<Input v-model="formData.stAmount" placeholder="请输入生物样本累计采集例数" />
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="本院SAE例次" prop="saeAmount">
							<Input
								v-model="formData.saeAmount"
								placeholder="请输入本院SAE例次"
								@on-change="() => handleDataChange('saeAmount', 'saeAmountStatus')"
							/>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="已质控次数" prop="pvAmount">
							<Input
								v-model="formData.pvAmount"
								placeholder="请输入已质控次数"
								@on-change="() => handleDataChange('pvAmount', 'pvAmountStatus')"
							/>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="上次质控时间" prop="pvDate">
							<DatePicker
								type="date"
								v-model="formData.pvDate"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd HH:mm:ss"
								placeholder="上次质控时间"
								@on-change="(v) => {
									formData.pvDate = v;
									handleDataChange('pvDate', 'pvDateStatus');
								}"
							>
							</DatePicker>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="上报方案违背/偏离例次" prop="pdPvNumber">
							<Input
								v-model="formData.pdPvNumber"
								placeholder="请输入上报方案违背/偏离例次"
								@on-change="() => handleDataChange('pdPvNumber', 'pdPvNumberStatus')"
							/>
						</FormItem>
					</Col>
				</Row>
			</Form>
		</div>
		<div class="page-footer">
			<Button type="primary" @click="onOk" :loading="loading">提交</Button>
		</div>
</div>
</template>

<script>
import api from "@/api/project/project"

const { getResearchWardInfo, postResearchWardInfo } = api

export default {
	name: "SupplementaryInfo",
	data() {
		return {
			loading: false,
			color: "#666",
			hasShownWarning: false,
			initialValues: {
				projectSchedule: '',
				saeAmount: '',
				pvAmount: '',
				pvDate: '',
				pdPvNumber: ''
			},
			warningShown: {
				projectSchedule: false,
				saeAmount: false,
				pvAmount: false,
				pvDate: false,
				pdPvNumber: false
			},
			formData: {
        projectId: this.$route.params.projectId,
        projectName: "", // 项目名称
        projectLevel: "", // 项目等级
        visitCycle: "", // 访视周期
        // stType: "", // 生物样本拟采集类型
        projectSchedule: "", // 项目进度（研究病房入驻信息专用信息）
        projectScheduleStatus: 0, // 项目进度是否改动，0未改动，1已改动
        primarySub: "", // 责任医生
        primaryNurse: "", // 责任护士
        cruContactPerson: "", // CRU对接人员（IIT项目填写）
        qcContactPerson: "", // 质控对接人（IST项目填写）
        // groupAmount: "", // 项目累计入组人数
        // visitAmount: "", // 病房内累计访视人次
        // hospDay: "", // 累计住院天数
        stAmount: "", // 生物样本累计采集例次
        saeAmount: "", // 本院SAE例次
        saeAmountStatus: 0, // 本院SAE例次是否改动，0未改动，1已改动
        pvAmount: "", // 已质控次数
        pvAmountStatus: 0, // 已质控次数是否改动，0未改动，1已改动
        pvDate: "", // 上次质控时间
        pvDateStatus: 0, // 上次质控时间是否改动，0未改动，1已改动
        pdPvNumber: "", // 上报方案违背/偏离例次
        pdPvNumberStatus: 0, // 上报方案违背/偏离例次是否改动，0未改动，1已改动
			},
			rules: {
				// componentValue: {
				// 	required: true,
				// 	message: "伦理批件签发日期不能为空",
				// 	trigger: "blur"
				// },
				// messageDate: {
				//   required: true, message: '短信提醒日期不能为空', trigger: 'blur',
				// },
				// notifyType: {
				// 	required: true,
				// 	type: "number",
				// 	message: "伦理批件有效期不能为空",
				// 	trigger: "change"
				// }
			}
		}
	},
	created() {
		this.getResearchWardInfo()
	},
	methods: {
		// 获取研究型病房信息
		getResearchWardInfo() {
      this.$asyncDo(async () => {
        const res = await getResearchWardInfo({projectId: this.formData.projectId})
        if (res.code === 0 && res.data) {
          this.formData = {
            ...this.formData,
            ...res.data
          }
          // 保存初始值
          this.initialValues = {
            projectSchedule: res.data.projectSchedule,
            saeAmount: res.data.saeAmount,
            pvAmount: res.data.pvAmount,
            pvDate: res.data.pvDate,
            pdPvNumber: res.data.pdPvNumber
          }
        }
		})
  },
		// 添加提示框方法
		showWarningModal(callback) {
			this.$Modal.warning({
				title: '提示',
				content: '<p style="color: #ff9900;">该数据来自于流程反馈，如您需要手动修改该字段将不再自动统计，请您定期手动维护</p>',
				onOk: () => {
					callback && callback()
				}
			})
		},
		// 处理数据修改
		handleDataChange(field, status) {
			// 比较当前值和初始值
			if (this.formData[field] === this.initialValues[field]) {
				this.formData[status] = 0  // 如果值相同，将状态改回0
			} else {
				if (!this.warningShown[field]) {
					this.showWarningModal(() => {
						this.formData[status] = 1
						this.warningShown[field] = true
					})
				} else {
					this.formData[status] = 1
				}
			}
		},
    handleChangeCheck(val) {
			this.formData.notifyType = val
			if (val === 100) {
				this.formData.notifyFrequency = this.formData.notifyFrequency || 2
			}
			if (val && this.formData.componentValue) {
				this.getMessageTime(this.formData.componentValue, val)
			} else {
				this.formData.messageDate = ""
			}
		},
		// 提交
		onOk() {
			this.$refs.supplementaryInfoForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await postResearchWardInfo({
							...this.formData
						})
						if (res) {
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
.content-wrap {
	.supplementary-info-form {
		background-color: #fff;
		margin: 24px 0;
	}
	
	.page-footer {
		background-color: #fff;
		text-align: right;
		position: sticky;
		bottom: 0;
		z-index: 10;
	}
	
	.reason-input {
		width: 500px;
		textarea.ivu-input {
			font-size: 14px;
		}
	}
}
</style>
