import request from "@/utils/request"

const getRequest = (url, data = null, method = "get") =>
	request(url, Object.assign({ method }, data ? { params: data } : {}))
const get = (url, data = null) => getRequest(url, data).then(resp => resp.data)
// const post = (url, data = null) => getMethod(url, data, 'post').then(resp => resp.data);

export default {
	// 获取申办方、SMO、CRO的项目统计报表
	apiGetProjectStatistical: () => get("/report/org-project-count-report"),

	// 获取项目流程进度报表
	apiGetProjectProgress: data =>
		request(`/report/project-process-progress-report`, {
			method: "get",
			params: data
		}),
	// 获取项目信息登记表
	apiGetProjectInfoReport: data =>
		request(`/report/project-info-register`, {
			method: "post",
			body: data
		}),
	// 获取项目信息报表
	apiGetProjectInfo: data => get("/report/project-info", data),
	// 根据别名获取表单组件信息详情
	apiGetConDetail: data => get("/common/form-component-alias", data),

	// 获取项目指定流程模版节点报表
	apiGetProcessNodeReport: data =>
		get("/report/project-process-node-report", data),

	// 获取系统指定最近月份的财务报表
	apiGetMonthReport: month => get("/report/finance/month-report", { month }),

	// 获取项目指定最近月份的财务报表
	apiGetProjectMonthReport: (projectId, month) =>
		get("/report/finance/project-month-report", {
			projectId,
			month
		}),

	// 获取系统指定最近年份的财务报表
	apiGetYearReport: year => get("/report/finance/year-report", { year }),
	// 咨询报表
	// 每日回复量统计报表
	apiGetDailyResponse: dateTime =>
		get("/report/consultation/dailyresponse", { dateTime }),
	// 咨询类型统计报表
	apiGetResearchType: year =>
		get("/report/consultation/researchtype", { year }),
	// 服务提供统计报表
	apiGetProvideService: year =>
		get("/report/consultation/provideservice", { year }),
	// 在研究中担任统计报表
	apiGetDuty: year => get("/report/consultation/duty", { year }),
	// 是否负责这个研究统计报表
	apiGetWhetherResponsible: year =>
		get("/report/consultation/whetherresponsible", { year }),
	// 是否向CRU咨询过这个研究项统计报表
	apiGetWhetherConsult: year =>
		get("/report/consultation/whetherconsult", { year }),
	// 获取项目合同信息报表
	apiGetContractAmount: () => get("/report/project-contract"),
	// 获取工时量统计
	apiGetWorkhour: data => get("/report/consultation/workhour", data),
	// 获取合同信息报表
	apiGetProjectContract: data => get("/report/contract/list", data),
	// 获取合同信息报表 分页
	apiGetProjectContractPage: data => get("/report/contract/list-page", data),
	// 获取伦理项目清单 报表  分页
	apiGetEthicalProjects: data => get("/report/ethic-project/list-page", data),
	// 获取结题报表  分页
	apiGetConclusion: data => get("/report/conclusion/list-page", data),
	// 获取档案报表  分页
	apiGetArchives: data => get("/report/file-manage/list-page", data),
	// 研究类型分类报表数据
	apiGetProjectTypeData: data => get("/report/project-type-data", data),
	// 中心类型分类报表数据
	apiGetSiteTypeData: data => get("/report/site-type", data),
	// 每年份项目报表数据
	apiGetYearData: data => get("/report/year-project-data", data),
	// 科室报表数据
	apiGetDepartData: data => get("/report/department-data", data),
	// 科室报表数据 apiAsyncGetDepartData
  apiAsyncGetDepartData: data =>
    request("/report/department-data", {
      method: "get",
      params: data
    }),
	// 科室报表数据
	apiGetAllProjectCountData: () => get("/report/all-project-count-data"),
	// 获取人遗样本核查报表
	apiGetGeneticSource: data => get("/report/get-resource-data-report", data),
	// 获取人遗行政许可自查报表
	apiGetGeneticLicense: data => get("/report/get-admin-data-report", data),
	// 伦理审查时长
	apiGetEthicalReview: data => get("/report/efficiency/ethical/review", data),
	// 伦理审查时长平均值
	apiGetEthicalAvg: data => get("/report/efficiency/ethical/avg", data),
	// 合同审查时长
	apiGetContractReview: data => get("/report/efficiency/contract/review", data),
	// 合同审查时长平均值
	apiGetContractAvg: data => get("/report/efficiency/contract/avg", data),
	// 人遗审查时长
	apiGetHgracReview: data => get("/report/efficiency/hgrac/review", data),
	// 人遗审查时长平均值
	apiGetHgracAvg: data => get("/report/efficiency/hgrac/avg", data),
	// 首例入组时长
	apiGetEntryReview: data => get("/report/efficiency/entry/review", data),
	// 首例入组时长平均值
	apiGetEntryAavg: data => get("/report/efficiency/entry/avg", data),
	// 招募时长
	apiGetRecruitReview: data => get("/report/efficiency/recruit/review", data),
	// 招募时长平均值
	apiGetRecruitAvg: data => get("/report/efficiency/recruit/avg", data),
	// IST中心里程碑
	apiGetIstReport: data => get("/report/site/milestone/ist/review", data),
	// IIT中心里程碑
	apiGetIitReport: data => get("/report/site/milestone/iit/review", data)
}
