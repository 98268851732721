<template>
	<!--按成果类型查阅详情-->
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<h3 style="padding: 0 0 5px 10px">{{ typeName }}详情</h3>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="项目名称">
				<Input
					placeholder="请输入项目名称"
					v-model="searchParams.projectName"
				/>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="exportData"
				v-if="systemActionPermissions.indexOf('btn_research_type_export') > -1"
			>
				导出
			</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="headers"
			:data="listData"
			:page="page"
			ref="table"
		>
			<template slot-scope="{ row }" slot="projectName">
				<a
					v-if="row.hasProjectPower === 1"
					@click="toProject(row)"
					href="javascript:;"
					>{{ row.projectName }}</a
				>
				<span v-else>{{ row.projectName }}</span>
			</template>
		</CtmsDataGrid>
	</div>
</template>
<script>
import { mapState } from "vuex"
import api from "@/api/achievement/achievement"

const { apiGetPage, getListHeader } = api
export default {
	data() {
		return {
			loading: false,
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			listData: [],
			headers: [],
			columns: [
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 120,
					slot: "projectName"
				}
			],
			searchParams: {
				projectName: ""
			},
			oldSearchParams: {}
		}
	},
	computed: {
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - 170
			},
			...mapState("permission", ["systemActionPermissions"])
		}),
		typeName() {
			return this.$route.query.name
		},
		typeId() {
			return this.$route.query.id
		}
	},
	created() {
		this.getHeader()
	},
	methods: {
		async getHeader() {
			const res = await getListHeader({
				templateId: this.typeId
			})
			if (res && res.data) {
				this.headers = [...this.columns]
				res.data.forEach(item => {
					const obj = {
						title: item.name,
						key: item.alias,
						minWidth: 120
					}
					this.headers.push(obj)
				})
				this.initList("init")
			}
		},
		// 获取数据
		async initList(type) {
			this.loading = true
			if (type === "init") {
				const paramsStr = sessionStorage.getItem("achieveTypeParams")
				if (paramsStr) {
					const { params, page } = JSON.parse(paramsStr)
					this.oldSearchParams = { ...params }
					this.searchParams = {
						...this.searchParams,
						...params
					}
					this.page.current = page.current
					this.page.pageSize = page.pageSize
				}
				sessionStorage.setItem("achieveTypeParams", "")
			}
			const res = await apiGetPage({
				...this.oldSearchParams,
				templateId: this.typeId,
				pageNum: this.page.current,
				pageSize: this.page.pageSize
			})
			if (res && res.data) {
				const list = res.data.list || []
				this.listData = list.map(item => ({
					...item,
					...item.map
				}))
				this.page.total = res.data.total
			}
			this.loading = false
		},
		// 跳转到项目工作台
		toProject(row) {
			sessionStorage.setItem(
				"achieveTypeParams",
				JSON.stringify({
					params: {
						...this.oldSearchParams
					},
					page: {
						current: this.page.current,
						pageSize: this.page.pageSize
					}
				})
			)
			window.history.pushState(
				{
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				},
				null,
				window.location.href
			)
			this.$router.push({
				name: "projectWorkbench",
				params: {
					projectId: row.projectId
				}
			})
		},
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				projectName: ""
			}
			this.searchHandle()
		},
		// 导出项目信息
		exportData() {
			let url = `${this.$baseUrl}/common-form/research-list-report?templateId=${this.typeId}&token=${this.$store.state.user.token}`
			const { projectName } = this.oldSearchParams
			url += `&projectName=${projectName || ""}`
			window.open(url)
		}
	}
}
</script>
